import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {createStore, Provider} from "jotai";
import {restaurantIDAtom} from "./jotai/restaurantDataAtoms";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {createTheme, ThemeProvider} from "@mui/material";
import LoadingApp from "./LoadingApp";

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
const store = createStore();

const App = lazy(() => import('./App'));

const theme = createTheme({
    typography: {
        fontFamily: 'Inter, sans-serif', // Set the font family to Poppins
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    display: 'none', // Hide the scrollbar
                },
                '*::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                },
                '*::-webkit-scrollbar-thumb': {
                    backgroundColor: 'transparent', // You can change this to your preferred color
                },
            },
        },
        MuiInput: {
            // This will target text fields
            '& input': {
                '&::-webkit-scrollbar': {
                    display: 'none', // Hide the scrollbar in text fields
                    width: "0px",
                },
            },
        },
    },
});

store.sub(restaurantIDAtom, () => {
    if (store.get(restaurantIDAtom) === -1) {
        queryClient.invalidateQueries({queryKey: ["restaurantid"]});
    }
});

root.render(
    <React.StrictMode>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet"/>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <QueryClientProvider client={queryClient}>
                        <Provider store={store}>
                            <Suspense fallback={<LoadingApp/>}>
                                <App/>
                            </Suspense>
                        </Provider>
                    </QueryClientProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();
